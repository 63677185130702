/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-use-before-define */
//                               Apache License
//                         Version 2.0, January 2004
//                      http://www.apache.org/licenses/
//  Source : https://github.com/danielcebrian/rangeslider-videojs
//  Edited by: @krats ( Karthik Bashetty )
//
// TERMS AND CONDITIONS FOR USE, REPRODUCTION, AND DISTRIBUTION
//
// 1. Definitions.
//
//    "License" shall mean the terms and conditions for use, reproduction,
//    and distribution as defined by Sections 1 through 9 of this document.
//
//    "Licensor" shall mean the copyright owner or entity authorized by
//    the copyright owner that is granting the License.
//
//    "Legal Entity" shall mean the union of the acting entity and all
//    other entities that control, are controlled by, or are under common
//    control with that entity. For the purposes of this definition,
//    "control" means (i) the power, direct or indirect, to cause the
//    direction or management of such entity, whether by contract or
//    otherwise, or (ii) ownership of fifty percent (50%) or more of the
//    outstanding shares, or (iii) beneficial ownership of such entity.
//
//    "You" (or "Your") shall mean an individual or Legal Entity
//    exercising permissions granted by this License.
//
//    "Source" form shall mean the preferred form for making modifications,
//    including but not limited to software source code, documentation
//    source, and configuration files.
//
//    "Object" form shall mean any form resulting from mechanical
//    transformation or translation of a Source form, including but
//    not limited to compiled object code, generated documentation,
//    and conversions to other media types.
//
//    "Work" shall mean the work of authorship, whether in Source or
//    Object form, made available under the License, as indicated by a
//    copyright notice that is included in or attached to the work
//    (an example is provided in the Appendix below).
//
//    "Derivative Works" shall mean any work, whether in Source or Object
//    form, that is based on (or derived from) the Work and for which the
//    editorial revisions, annotations, elaborations, or other modifications
//    represent, as a whole, an original work of authorship. For the purposes
//    of this License, Derivative Works shall not include works that remain
//    separable from, or merely link (or bind by name) to the interfaces of,
//    the Work and Derivative Works thereof.
//
//    "Contribution" shall mean any work of authorship, including
//    the original version of the Work and any modifications or additions
//    to that Work or Derivative Works thereof, that is intentionally
//    submitted to Licensor for inclusion in the Work by the copyright owner
//    or by an individual or Legal Entity authorized to submit on behalf of
//    the copyright owner. For the purposes of this definition, "submitted"
//    means any form of electronic, verbal, or written communication sent
//    to the Licensor or its representatives, including but not limited to
//    communication on electronic mailing lists, source code control systems,
//    and issue tracking systems that are managed by, or on behalf of, the
//    Licensor for the purpose of discussing and improving the Work, but
//    excluding communication that is conspicuously marked or otherwise
//    designated in writing by the copyright owner as "Not a Contribution."
//
//    "Contributor" shall mean Licensor and any individual or Legal Entity
//    on behalf of whom a Contribution has been received by Licensor and
//    subsequently incorporated within the Work.
//
// 2. Grant of Copyright License. Subject to the terms and conditions of
//    this License, each Contributor hereby grants to You a perpetual,
//    worldwide, non-exclusive, no-charge, royalty-free, irrevocable
//    copyright license to reproduce, prepare Derivative Works of,
//    publicly display, publicly perform, sublicense, and distribute the
//    Work and such Derivative Works in Source or Object form.
//
// 3. Grant of Patent License. Subject to the terms and conditions of
//    this License, each Contributor hereby grants to You a perpetual,
//    worldwide, non-exclusive, no-charge, royalty-free, irrevocable
//    (except as stated in this section) patent license to make, have made,
//    use, offer to sell, sell, import, and otherwise transfer the Work,
//    where such license applies only to those patent claims licensable
//    by such Contributor that are necessarily infringed by their
//    Contribution(s) alone or by combination of their Contribution(s)
//    with the Work to which such Contribution(s) was submitted. If You
//    institute patent litigation against any entity (including a
//    cross-claim or counterclaim in a lawsuit) alleging that the Work
//    or a Contribution incorporated within the Work constitutes direct
//    or contributory patent infringement, then any patent licenses
//    granted to You under this License for that Work shall terminate
//    as of the date such litigation is filed.
//
// 4. Redistribution. You may reproduce and distribute copies of the
//    Work or Derivative Works thereof in any medium, with or without
//    modifications, and in Source or Object form, provided that You
//    meet the following conditions:
//
//    (a) You must give any other recipients of the Work or
//        Derivative Works a copy of this License; and
//
//    (b) You must cause any modified files to carry prominent notices
//        stating that You changed the files; and
//
//    (c) You must retain, in the Source form of any Derivative Works
//        that You distribute, all copyright, patent, trademark, and
//        attribution notices from the Source form of the Work,
//        excluding those notices that do not pertain to any part of
//        the Derivative Works; and
//
//    (d) If the Work includes a "NOTICE" text file as part of its
//        distribution, then any Derivative Works that You distribute must
//        include a readable copy of the attribution notices contained
//        within such NOTICE file, excluding those notices that do not
//        pertain to any part of the Derivative Works, in at least one
//        of the following places: within a NOTICE text file distributed
//        as part of the Derivative Works; within the Source form or
//        documentation, if provided along with the Derivative Works; or,
//        within a display generated by the Derivative Works, if and
//        wherever such third-party notices normally appear. The contents
//        of the NOTICE file are for informational purposes only and
//        do not modify the License. You may add Your own attribution
//        notices within Derivative Works that You distribute, alongside
//        or as an addendum to the NOTICE text from the Work, provided
//        that such additional attribution notices cannot be construed
//        as modifying the License.
//
//    You may add Your own copyright statement to Your modifications and
//    may provide additional or different license terms and conditions
//    for use, reproduction, or distribution of Your modifications, or
//    for any such Derivative Works as a whole, provided Your use,
//    reproduction, and distribution of the Work otherwise complies with
//    the conditions stated in this License.
//
// 5. Submission of Contributions. Unless You explicitly state otherwise,
//    any Contribution intentionally submitted for inclusion in the Work
//    by You to the Licensor shall be under the terms and conditions of
//    this License, without any additional terms or conditions.
//    Notwithstanding the above, nothing herein shall supersede or modify
//    the terms of any separate license agreement you may have executed
//    with Licensor regarding such Contributions.
//
// 6. Trademarks. This License does not grant permission to use the trade
//    names, trademarks, service marks, or product names of the Licensor,
//    except as required for reasonable and customary use in describing the
//    origin of the Work and reproducing the content of the NOTICE file.
//
// 7. Disclaimer of Warranty. Unless required by applicable law or
//    agreed to in writing, Licensor provides the Work (and each
//    Contributor provides its Contributions) on an "AS IS" BASIS,
//    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or
//    implied, including, without limitation, any warranties or conditions
//    of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A
//    PARTICULAR PURPOSE. You are solely responsible for determining the
//    appropriateness of using or redistributing the Work and assume any
//    risks associated with Your exercise of permissions under this License.
//
// 8. Limitation of Liability. In no event and under no legal theory,
//    whether in tort (including negligence), contract, or otherwise,
//    unless required by applicable law (such as deliberate and grossly
//    negligent acts) or agreed to in writing, shall any Contributor be
//    liable to You for damages, including any direct, indirect, special,
//    incidental, or consequential damages of any character arising as a
//    result of this License or out of the use or inability to use the
//    Work (including but not limited to damages for loss of goodwill,
//    work stoppage, computer failure or malfunction, or any and all
//    other commercial damages or losses), even if such Contributor
//    has been advised of the possibility of such damages.
//
// 9. Accepting Warranty or Additional Liability. While redistributing
//    the Work or Derivative Works thereof, You may choose to offer,
//    and charge a fee for, acceptance of support, warranty, indemnity,
//    or other liability obligations and/or rights consistent with this
//    License. However, in accepting such obligations, You may act only
//    on Your own behalf and on Your sole responsibility, not on behalf
//    of any other Contributor, and only if You agree to indemnify,
//    defend, and hold each Contributor harmless for any liability
//    incurred by, or claims asserted against, such Contributor by reason
//    of your accepting any such warranty or additional liability.
//
// END OF TERMS AND CONDITIONS
//
// APPENDIX: How to apply the Apache License to your work.
//
//    To apply the Apache License to your work, attach the following
//    boilerplate notice, with the fields enclosed by brackets "{}"
//    replaced with your own identifying information. (Don't include
//    the brackets!)  The text should be enclosed in the appropriate
//    comment syntax for the file format. We also recommend that a
//    file or class name and description of purpose be included on the
//    same "printed page" as the copyright notice for easier
//    identification within third-party archives.
//
// Copyright {yyyy} {name of copyright owner}
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import videojs from 'video.js';

var version = '0.0.0';

// Default options for the plugin.
var defaults = {};

// Cross-compatibility for Video.js 5 and 6.
var registerPlugin = videojs.registerPlugin || videojs.plugin;

var onPlayerReady = function onPlayerReady(player, options) {
  player.addClass('vjs-videojs-trimcontrols-plugin');
};

var trimControlsPlugin = function trimControlsPlugin(options) {
  var _this = this;

  var player = this;
  player.trimControls = new TrimControls(player, options);

  this.ready(function () {
    onPlayerReady(_this, videojs.mergeOptions(defaults, options));
  });

  function initialVideoFinished(event) {
    var plugin = player.trimControls;
    for (var index in plugin.components) {
      plugin.components[index].init_();
    }
    player.trimControls.setValue(1, player.duration());
    player.trigger('loadedTrimControls'); //Let know if the Range Slider DOM is ready
  }
  initialVideoFinished();
  player.on('loadeddata', initialVideoFinished);
};

function TrimControls(player, options) {
  player = player || this;

  this.player = player;

  this.options = options;

  this.components = {};

  if (!options.hasOwnProperty('hidden')) options.hidden = true;

  this.init();
}

TrimControls.prototype = {
  init: function init() {
    var player = this.player || {};

    this.updatePrecision = 3;

    //position in second of the arrows
    this.start = 0;
    this.end = 0;

    //components of the plugin
    var controlBar = player.controlBar;
    var seekBar = controlBar.progressControl.seekBar;
    this.components.TrimControlsContainer = seekBar.TrimControlsContainer;

    //Save local component
    this.rstb = this.components.TrimControlsContainer;
    this.box = this.components.SeekTCBar = this.rstb.SeekTCBar;
    this.bar = this.components.SelectionBar = this.box.SelectionBar;
    this.left = this.components.TrimControlLeft = this.box.TrimControlLeft;
    this.right = this.components.TrimControlRight = this.box.TrimControlRight;
  },
  lock: function lock() {
    this.options.locked = true;
    if (typeof this.box != 'undefined') videojsAddClass(this.box.el_, 'locked');
  },
  unlock: function unlock() {
    this.options.locked = false;
    if (typeof this.box != 'undefined') videojsRemoveClass(this.box.el_, 'locked');
  },
  show: function show() {
    this.options.hidden = false;
    if (typeof this.rstb != 'undefined') {
      this.rstb.show();
    }
  },
  hide: function hide() {
    this.options.hidden = true;
    if (typeof this.rstb != 'undefined') {
      this.rstb.hide();
    }
  },
  setValue: function setValue(index, seconds, writeControlTime, duration) {
    if (!isNaN(seconds)) {
      var writeControlTime = typeof writeControlTime != 'undefined' ? writeControlTime : true;
      var percent = this._percent(seconds, duration);
      var isValidIndex = index === 0 || index === 1;
      var isChangeable = !this.locked;
      if (isChangeable && isValidIndex)
        this.box.setPosition(index, percent, writeControlTime, true);
    }
  },
  setValues: function setValues(start, end, writeControlTime) {
    //index = 0 for the left Arrow and 1 for the right Arrow. Value in seconds
    var writeControlTime = typeof writeControlTime != 'undefined' ? writeControlTime : true;

    this._reset();

    this._setValuesLocked(start, end, writeControlTime);
  },
  getValues: function getValues() {
    var values = {},
      start,
      end;
    start = this.start || this._getArrowValue(0);
    end = this.end || this._getArrowValue(1);
    return { start: start, end: end };
  },
  playBetween: function playBetween(start, end) {
    start = parseFloat(parseFloat(start + 0.001).toFixed(3));
    end = parseFloat(parseFloat(end).toFixed(3));
    if (this.player.currentTime() < start || this.player.currentTime() >= parseInt(end)) {
      if (!isNaN(start)) this.player.currentTime(start);
    }
    if (this.player.paused()) this.player.play();

    this.bar.activatePlay(start, end);
  },
  _getArrowValue: function _getArrowValue(index) {
    var index = index || 0;
    var duration = this.player.duration();
    duration = typeof duration == 'undefined' ? 0 : duration;
    var percentage = this[index === 0 ? 'left' : 'right'].el_.style.left.replace('%', '');
    if (percentage == '') percentage = index === 0 ? 0 : 100;
    return videojsRound(this._seconds(percentage / 100), this.updatePrecision - 1);
  },
  _percent: function _percent(seconds, duration) {
    if (!duration) duration = this.player.duration();
    if (isNaN(duration)) {
      return 0;
    }
    return Math.min(1, Math.max(0, seconds / duration));
  },
  _seconds: function _seconds(percent) {
    var duration = this.player.duration();
    if (isNaN(duration)) {
      return 0;
    }
    return Math.min(duration, Math.max(0, percent * duration));
  },
  _reset: function _reset() {
    var duration = this.player.duration();
    this.left.el_.style.left = '0%';
    this.right.el_.style.left = '100%';
    this._setValuesLocked(0, duration);
  },
  _setValuesLocked: function _setValuesLocked(start, end, writeControlTime) {
    var writeControlTime = typeof writeControlTime != 'undefined' ? writeControlTime : true;
    if (this.options.locked) {
      this.unlock(); //It is unlocked to change the bar position. In the end it will return the value.
      this.setValue(0, start, writeControlTime);
      this.setValue(1, end, writeControlTime);
      this.lock();
    } else {
      this.setValue(0, start, writeControlTime);
      this.setValue(1, end, writeControlTime);
    }
  },
};

// Register the plugin with video.js.
registerPlugin('trimControlsPlugin', trimControlsPlugin);

// Include the version number.
trimControlsPlugin.VERSION = version;

var videojsSeekBar = videojs.getComponent('SeekBar');
videojsSeekBar.prototype.options_.children.push('TrimControlsContainer');

var videojsComponent = videojs.getComponent('Component');

var videojsAddClass = function videojsAddClass(element, className) {
  element.classList.add(className);
};
var videojsRemoveClass = function videojsRemoveClass(element, className) {
  element.classList.remove(className);
};
var videojsRound = function videojsRound(n, precision) {
  return parseFloat(n);
};
var videojsFormatTime = function videojsFormatTime(totalSeconds) {
  var minutes = Math.floor(totalSeconds / 60).toFixed(0);
  var seconds = (totalSeconds % 60).toFixed(0);

  if (seconds.length === 1) {
    seconds = '0' + seconds;
  }
  return minutes + ':' + seconds;
};

var videojsTrimControlsContainer = videojs.extend(videojsComponent, {
  constructor: function constructor(player, options) {
    videojsComponent.call(this, player, options);
  },
});

videojsTrimControlsContainer.prototype.init_ = function () {
  this.rs = this.player_.trimControls;
};

videojsTrimControlsContainer.prototype.options_ = {
  children: {
    SeekTCBar: {},
  },
};

videojsTrimControlsContainer.prototype.createEl = function () {
  return videojsComponent.prototype.createEl.call(this, 'div', {
    className: 'vjs-trim-controls',
    innerHTML: '',
  });
};

videojs.registerComponent('TrimControlsContainer', videojsTrimControlsContainer);

var videojsSeekTCBar = videojs.extend(videojsSeekBar, {
  constructor: function constructor(player, options) {
    videojsComponent.call(this, player, options);
    this.on('mousedown', this.onMouseDown);
    this.on('touchstart', this.onMouseDown);
  },
});

videojsSeekTCBar.prototype.init_ = function () {
  this.rs = this.player_.trimControls;
};

videojsSeekTCBar.prototype.options_ = {
  children: {
    SelectionBar: {},
    TrimControlLeft: {},
    TrimControlRight: {},
  },
};

videojsSeekTCBar.prototype.createEl = function () {
  return videojsComponent.prototype.createEl.call(this, 'div', {
    className: 'vjs-trimcontrols-holder',
  });
};

videojsSeekTCBar.prototype.onMouseDown = function (event) {
  event.preventDefault();

  if (!this.rs.options.locked) {
    this.on(document, 'mousemove', videojs.bind(this, this.onMouseMove));
    this.on(document, 'mouseup', videojs.bind(this, this.onMouseUp));
    this.on(document, 'touchmove', videojs.bind(this, this.onMouseMove));
    this.on(document, 'touchend', videojs.bind(this, this.onMouseUp));
  }
};

videojsSeekTCBar.prototype.onMouseUp = function (event) {
  this.off(document, 'mousemove', videojs.bind(this, this.onMouseMove), false);
  this.off(document, 'mouseup', videojs.bind(this, this.onMouseUp), false);
  this.off(document, 'touchmove', videojs.bind(this, this.onMouseMove), false);
  this.off(document, 'touchend', videojs.bind(this, this.onMouseUp), false);
};

videojsSeekTCBar.prototype.onMouseMove = function (event) {
  var left = this.calculateDistance(event);

  if (this.rs.left.pressed) this.setPosition(0, left);
  else if (this.rs.right.pressed) this.setPosition(1, left);

  var ctd = this.player_.controlBar.currentTimeDisplay;
  ctd.contentEl_.innerHTML =
    '<span class="vjs-control-text">' +
    ctd.localize('Current Time') +
    '</span>' +
    videojsFormatTime(this.rs._seconds(left), this.player_.duration());
};

/*shouldNotCheckForMinimum is to ensure minimum diff only when it is required (i.e only when video is loaded)
 */
videojsSeekTCBar.prototype.setPosition = function (
  index,
  left,
  writeControlTime,
  shouldNotCheckForMinimum,
) {
  writeControlTime = typeof writeControlTime !== 'undefined' ? writeControlTime : true;
  //index = 0 for left side, index = 1 for right side
  index = index || 0;

  // Position shouldn't change when handle is locked
  if (this.rs.options.locked) return false;

  // Check for invalid position
  if (isNaN(left)) return false;

  // Check index between 0 and 1
  if (!(index === 0 || index === 1)) return false;

  var ObjLeft = this.rs.left.el_,
    ObjRight = this.rs.right.el_,
    Obj = this.rs[index === 0 ? 'left' : 'right'].el_,
    bar = this.rs.bar;

  if (index === 0 ? bar.updateLeft(left) : bar.updateRight(left)) {
    Obj.style.left = left * 100 + '%';
    index === 0 ? bar.updateLeft(left) : bar.updateRight(left);

    this.rs[index === 0 ? 'start' : 'end'] = this.rs._seconds(left);

    if (index === 0) {
      if (left >= 0.9) ObjLeft.style.zIndex = 25;
      else ObjLeft.style.zIndex = 10;
    }
  }
  /*If left trim control is moved we set it to right value - 1
      If right trim control is moved we set it to left value + 1
    */
  if (!shouldNotCheckForMinimum && this.rs.getValues().end - this.rs.getValues().start < 1) {
    if (index === 0) {
      this.rs.setValue(0, this.rs.getValues().end - 1);
    } else {
      this.rs.setValue(1, this.rs.getValues().start + 1);
    }
  }
  this.player_.trigger('TrimControlsMoved', this.rs.getValues());
  return true;
};

videojs.registerComponent('SeekTCBar', videojsSeekTCBar);

var videojsSelectionBar = videojs.extend(videojsComponent, {
  constructor: function constructor(player, options) {
    videojsComponent.call(this, player, options);
    this.on('mouseup', this.onMouseUp);
    this.on('touchend', this.onMouseUp);
    this.fired = false;
  },
});

videojsSelectionBar.prototype.init_ = function () {
  this.rs = this.player_.trimControls;
  this.processPlay = videojs.bind(this, this._processPlay);
};

videojsSelectionBar.prototype.createEl = function () {
  return videojsComponent.prototype.createEl.call(this, 'div', {
    className: 'vjs-selectionbar-trimcontrols',
  });
};

videojsSelectionBar.prototype.onMouseUp = function () {
  var start = this.rs.left.el_.style.left.replace('%', ''),
    end = this.rs.right.el_.style.left.replace('%', ''),
    duration = this.player_.duration(),
    precision = this.rs.updatePrecision,
    segStart = videojsRound((start * duration) / 100, precision),
    segEnd = videojsRound((end * duration) / 100, precision);
  this.player_.currentTime(segStart);
  this.player_.play();
  this.rs.bar.activatePlay(segStart, segEnd);
};

videojsSelectionBar.prototype.updateLeft = function (left) {
  var rightVal = this.rs.right.el_.style.left != '' ? this.rs.right.el_.style.left : 100;
  var right = parseFloat(rightVal) / 100;
  var width = videojsRound(right - left, this.rs.updatePrecision);
  if (left <= right + 0.00001) {
    this.rs.bar.el_.style.left = left * 100 + '%';
    this.rs.bar.el_.style.width = width * 100 + '%';
    return true;
  }
  return false;
};

videojsSelectionBar.prototype.updateRight = function (right) {
  var leftVal = this.rs.left.el_.style.left != '' ? this.rs.left.el_.style.left : 0;
  var left = parseFloat(leftVal) / 100;
  var width = videojsRound(right - left, this.rs.updatePrecision);
  if (right + 0.00001 >= left) {
    this.rs.bar.el_.style.width = width * 100 + '%';
    this.rs.bar.el_.style.left = (right - width) * 100 + '%';
    return true;
  }
  return false;
};

videojsSelectionBar.prototype.activatePlay = function (start, end) {
  this.timeStart = start;
  this.timeEnd = end;
  this.suspendPlay();
  this.player_.on('timeupdate', this.processPlay);
};

videojsSelectionBar.prototype.suspendPlay = function () {
  this.fired = false;
  this.player_.off('timeupdate', this.processPlay);
};

videojsSelectionBar.prototype._processPlay = function () {
  if (
    this.player_.currentTime() >= this.timeStart &&
    (this.timeEnd < 0 || this.player_.currentTime() < this.timeEnd)
  ) {
    if (this.fired) {
      return;
    }
    this.fired = true; //Set fired flag to true
  } else {
    if (!this.fired) {
      //Do nothing if end has already been called
      return;
    }
    this.fired = false; //Set fired flat to false
    this.player_.currentTime(this.timeStart);
    this.player_.pause(); //Call end function
    this.suspendPlay();
  }
};

videojs.registerComponent('SelectionBar', videojsSelectionBar);

var videojsTrimControlLeft = videojs.extend(videojsComponent, {
  constructor: function constructor(player, options) {
    videojsComponent.call(this, player, options);
    this.on('mousedown', this.onMouseDown);
    this.on('touchstart', this.onMouseDown);
    this.pressed = false;
  },
});

videojsTrimControlLeft.prototype.init_ = function () {
  this.rs = this.player_.trimControls;
};

videojsTrimControlLeft.prototype.createEl = function () {
  return videojsComponent.prototype.createEl.call(this, 'div', {
    className: 'vjs-trim-control-left',
    innerHTML: '',
  });
};

videojsTrimControlLeft.prototype.onMouseDown = function (event) {
  event.preventDefault();
  if (!this.rs.options.locked) {
    this.pressed = true;
    this.on(document, 'mouseup', videojs.bind(this, this.onMouseUp));
    this.on(document, 'touchend', videojs.bind(this, this.onMouseUp));
  }
};

videojsTrimControlLeft.prototype.onMouseUp = function (event) {
  this.off(document, 'mouseup', videojs.bind(this, this.onMouseUp), false);
  this.off(document, 'touchend', videojs.bind(this, this.onMouseUp), false);
  if (!this.rs.options.locked) {
    this.pressed = false;
  }
};

videojs.registerComponent('TrimControlLeft', videojsTrimControlLeft);

var videojsTrimControlRight = videojs.extend(videojsComponent, {
  constructor: function constructor(player, options) {
    videojsComponent.call(this, player, options);
    this.on('mousedown', this.onMouseDown);
    this.on('touchstart', this.onMouseDown);
    this.pressed = false;
  },
});

videojsTrimControlRight.prototype.init_ = function () {
  this.rs = this.player_.trimControls;
};

videojsTrimControlRight.prototype.createEl = function () {
  return videojsComponent.prototype.createEl.call(this, 'div', {
    className: 'vjs-trim-control-right',
    innerHTML: '',
  });
};

videojsTrimControlRight.prototype.onMouseDown = function (event) {
  event.preventDefault();
  if (!this.rs.options.locked) {
    this.pressed = true;
    this.on(document, 'mouseup', videojs.bind(this, this.onMouseUp));
    this.on(document, 'touchend', videojs.bind(this, this.onMouseUp));
  }
};

videojsTrimControlRight.prototype.onMouseUp = function (event) {
  this.off(document, 'mouseup', videojs.bind(this, this.onMouseUp), false);
  this.off(document, 'touchend', videojs.bind(this, this.onMouseUp), false);
  if (!this.rs.options.locked) {
    this.pressed = false;
  }
};

videojs.registerComponent('TrimControlRight', videojsTrimControlRight);

export default trimControlsPlugin;
